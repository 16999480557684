<template>
  <div class="table_pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      background
      :total="total"
      :page-sizes="pageSizes"
    >
    </el-pagination>
  </div>
</template>

<script>
import { TABLE } from '/app.config.js';
export default {
  props: {
    total: {
      type: Number,
      required: true,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 20
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1
    }
  },
  data() {
    return {
      pageSizes: TABLE.page_sizes
    };
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('fetch', { currentPageSize: val });
    },
    handleCurrentChange(val) {
      this.$emit('fetch', { currentPage: val });
    }
  }
};
</script>

<style lang="scss" scoped></style>

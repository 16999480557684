<template>
  <div class="LSDconfig">
    <el-row style="margin-bottom: 20px">
      <el-col :span="4">
        <el-button @click="addFile()">+ 添加设备</el-button>
      </el-col>
    </el-row>
    <div class="gridMain">
      <Grid :Gridtype="7" :GridData="data" :isUpdate="true" :isDel="true" @deleteRow="deleteRow" @editRow="edit" />
    </div>
    <Pagination class="pagination fr" @fetch="changePage" v-bind="pageoption" />
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="form" label-width="120px">
        <el-form-item label="工厂结构：" :rules="[{ required: true, message: '请选择工厂结构' }]">
          <el-cascader style="width: 95%" v-model="treeValue" :options="treedata" :props="defaultProps" @change="handleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="设备：" :rules="[{ required: true, message: '请选择设备' }]">
          <el-select style="width: 95%" v-model="deviceId" placeholder="请选择设备" @change="changeDevice">
            <el-option v-for="item in deviceList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参数：" :rules="[{ required: true, message: '请勾选参数' }]" v-if="isParams">
          <el-checkbox v-for="item in paramsList" v-model="item.isShow" :key="item.paramId">{{ item.paramName }}</el-checkbox>
        </el-form-item>
        <el-form-item label="上传：" v-if="isParams">
          <Upload
            :setimgUrl="iconUrl"
            :currenttype="11"
            @getimgUrl="
              item => {
                iconUrl = item;
              }
            "
            @geticonId="
              item => {
                iconId = item;
              }
            "
          ></Upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Grid from '@/components/grid/index.vue';
import Pagination from '@/components/pagination/pagination';
import Upload from '@/components/upload/uploadimg/index.vue';
export default {
  name: 'LSDconfig',
  components: { Grid, Upload, Pagination },
  data() {
    return {
      data: [],
      title: '',
      dialogVisible: false,
      deviceId: '',
      deviceList: [],
      isParams: false,
      paramsList: [],
      treedata: [],
      treeValue: [],
      iconUrl: '',
      iconId: '',
      isSave: false,
      deviceDetail: {},
      pageoption: {
        total: 0,
        pageSize: 20,
        currentPage: 1
      },
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id',
        expandTrigger: 'hover',
        checkStrictly: true
      }
    };
  },
  mounted() {
    this.init();
    this.getTree();
  },
  methods: {
    async init(param = {}) {
      const { currentPage, pageSize } = this.pageoption;

      let data = {
        currentPage: param.currentPage || currentPage,
        currentPageSize: param.currentPageSize || pageSize
      };
      const res = await this.$apis.Map3dConfig.get(data);
      const { items, total } = res;
      this.pageoption.total = total;
      this.pageoption.currentPage = res.currentPage;
      this.pageoption.pageSize = res.currentPageSize;
      this.data = items;
    },
    async changeDevice(data) {
      if (data) {
        this.isParams = true;
        this.paramsList = [];
        const res = await this.$apis.Map3dConfig.getparams({ deviceId: data });
        this.paramsList = res;
      }
    },
    async handleChange() {
      const data = {
        path: this.treeValue[this.treeValue.length - 1]
      };
      const res = await this.$apis.LSDconfig.getdevice(data);
      this.deviceList = res;
    },
    async getTree() {
      this.treedata = await this.$apis.FactoryChart.list();
    },
    addFile() {
      this.isSave = true;
      this.isParams = false;
      this.paramsList = [];
      this.deviceId = '';
      this.iconUrl = '';
      this.title = '添加设备';
      this.dialogVisible = true;
    },
    async deleteRow(data) {
      this.$confirm('确定删除改设备吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.Map3dConfig.del({ deviceId: data.deviceId });
        this.init();
        if (!fail) {
          return false;
        }
      });
    },
    async save() {
      if (!this.deviceId) {
        this.$message.warning('请选择设备');
        return false;
      }
      let paramIds = [];
      let paramTags = [];
      this.paramsList.forEach(item => {
        if (item.isShow) {
          paramIds.push(item.paramId);
          paramTags.push(item.tag);
        }
      });
      if (!paramIds.length) {
        this.$message.warning('未勾选参数');
        return false;
      }
      if (paramIds.length > 4) {
        this.$message.warning('参数至多勾选4个');
        return false;
      }
      let data = {
        deviceId: this.deviceId,
        paramIds,
        paramTags,
        iconUrl: this.iconUrl,
        iconId: this.iconId,
        factoryPath: this.treeValue,
        dimensionPanelId: this.deviceDetail.dimensionPanelId
      };
      if (this.isSave) {
        const { fail } = await this.$apis.Map3dConfig.save(data);
        if (fail) {
          return false;
        }
        this.isParams = false;
        this.paramsList = [];
        this.dialogVisible = false;
        this.init();
      } else {
        const { fail } = await this.$apis.Map3dConfig.update(data);
        if (fail) {
          return false;
        }
        this.isParams = false;
        this.paramsList = [];
        this.dialogVisible = false;
        this.init();
      }
    },
    async edit(data) {
      this.iconUrl = data.iconUrl;
      this.title = '编辑设备';
      this.dialogVisible = true;
      this.treeValue = data.factoryPath.split(',');
      for (let i = 0; i < this.treeValue.length; i++) {
        this.treeValue[i] = this.treeValue[i] - 0;
      }
      this.handleChange();
      this.isSave = false;
      this.deviceDetail = data;
      if (data) {
        const res = await this.$apis.Map3dConfig.getdetail({ dimensionPanelId: data.dimensionPanelId });
        this.deviceId = res.deviceId;
        this.isParams = true;
        const params = await this.$apis.Map3dConfig.getparams({ deviceId: data.deviceId, dimensionPanelId: data.dimensionPanelId });
        params.forEach(item => {
          if (item.isShow) {
            item.isShow = true;
          } else {
            item.isShow = false;
          }
        });
        this.paramsList = params;
      }
    },
    handleClose() {
      this.deviceId = '';
      this.isParams = false;
      this.paramsList = [];
      this.dialogVisible = false;
      this.treeValue = [];
    },
    changePage(item) {
      this.init(item);
    }
  }
};
</script>
<style scoped lang="scss">
.gridMain {
  height: 700px;
  overflow-y: scroll;
}
</style>

/* eslint-disable consistent-this */
<template>
  <div class="grid_view">
    <!-- 新版卡片 -->
    <div class="grid_box" v-for="(item, index) in comData" :key="index" @click="$emit('intoGrid', item)">
      <div class="grid_icon">
        <img class="icon_img" :src="`${baseip}${item.iconUrl}`" />
      </div>
      <div class="grid_detail">
        <span :title="item.name" class="detail_name">{{ item.name || item.deviceName }}</span>
        <span class="detail_status" v-if="isStatus" :class="item.deviceStatus === 2 ? 'OFFLINES' : item.deviceStatus === 1 ? 'ONLINES' : 'INACTIVES'">
          <span class="Status">{{ item.deviceStatus === 2 ? '离线' : item.deviceStatus === 1 ? '在线' : '未激活' }}</span>
          <span class="bind iconfont icon-dcicon-yibangding" v-if="isMove && item.deviceTypeId !== 0">已绑定</span>
        </span>
        <span :title="item.code" class="detail_code" v-if="!isStatus">{{ item.code || item.deviceTypeName || '' }}</span>
        <span class="detail_amount" title="被引用数量" v-if="!isStatus">{{ item.nestNumber || item.deviceNumber || item.templateNumber }}</span>
      </div>
      <!--      <div class="grid_btn" v-if="isMove || isBtnHidden">-->
      <!--        <div class="grid_group" v-if="item.isBtnGroupOpen">-->
      <!--          <div class="btn_icon btn_export" title="导出" @click.stop="$emit('export', item)" v-if="isExport && Gridtype !== 1">-->
      <!--            <i title="导出" class="iconfont icon-dcdaochu1"></i>-->
      <!--          </div>-->
      <!--          <div class="btn_icon btn_Edit" title="编辑" @click.stop="$emit('editRow', item)" v-if="isUpdate && !isMove"><i title="编辑" class="iconfont icon-dca-bianji1"></i></div>-->
      <!--          <div class="btn_icon btn_Edit" title="绑定" @click.stop="$emit('bind', item)" v-if="isMove && item.deviceTypeId === 0">-->
      <!--            <i title="绑定" class="iconfont icon-dcicon-yibangding"></i>-->
      <!--          </div>-->
      <!--          <div v-else>-->
      <!--            <div class="btn_icon btn_Edit" title="解绑" @click.stop="$emit('unbind', item)" v-if="Gridtype !== 4 && item.deviceTypeId && Gridtype !== 5">-->
      <!--              <i title="解绑" class="iconfont icon-dcicon-weibangding"></i>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="btn_icon btn_Del" title="删除" @click.stop="$emit('deleteRow', item)" v-if="isDel && !isMove"><i title="删除" class="iconfont icon-dcshanchu"></i></div>-->
      <!--        </div>-->
      <!--        <div class="grid_more" @click.stop="moreOpen(item)">...</div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
/**
 * Grid组件
 * 接收数据
 * @Gridtype      Grid类型   // 1:公共参数  2：基础参数组 3：嵌套参数组 4：设备模板 5：设备类型 6：设备信息
 * @GridData      Grid数据
 * @isAdd         添加按钮权限
 * @isDel         删除按钮权限
 * @isExport      导出按钮权限
 * @isUpdate      修改按钮权限
 * @isBtnHidden   按钮组隐藏
 * @staticIcon   引用资源中心的图片
 * 可监听事件
 * @add           添加事件
 * @export        导出事件------接收当前item全部数据
 * @editRow       修改数据------接收当前item全部数据
 * @deleteRow     删除数据------接收当前item全部数据
 * @intoGrid      进入当前参数组-接收当前item全部数据
 * @move          转移设备----接收当前item全部数据
 */
export default {
  name: 'gridname',
  data() {
    return {
      // 添加字符
      AddName: '',
      comData: this.GridData
    };
  },
  props: {
    isBtnHidden: {
      type: Boolean,
      default: true
    },
    isStatus: {
      type: Boolean,
      default: false
    },
    Gridtype: {
      type: Number,
      require: true
    },
    isAdd: {
      type: Boolean,
      require: true
    },
    isMove: {
      type: Boolean,
      require: true
    },
    isDel: {
      type: Boolean,
      require: true
    },
    isExport: {
      type: Boolean,
      require: true
    },
    isUpdate: {
      type: Boolean,
      require: true
    },
    GridData: {
      type: Array,
      require: true
    },
    staticIcon: Boolean
  },
  created() {
    this.comData = this.GridData;
    if (this.Gridtype === 6) {
      this.baseip = this.staticIcon ? process.env.VUE_APP_AESSETS_URL : process.env.VUE_APP_AESSETS_URL;
    } else {
      this.baseip = this.staticIcon ? process.env.VUE_APP_ICONIMG_URL : process.env.VUE_APP_ICONIMG_URL;
    }
    this.init();
  },
  mounted() {
    window.addEventListener('click', () => {
      this.comData.forEach(item => {
        item.isBtnGroupOpen = false;
        this.$forceUpdate();
      });
    });
  },
  watch: {
    GridData() {
      this.comData = this.GridData;
      this.comData.forEach(item => {
        item.id = item.id || item.deviceId || item.paramId || item.groupId || item.templateId || item.deviceTypeId;
      });
      this.comData = this.GridData;
    },
    deep: true
  },
  methods: {
    moreOpen(data) {
      if (this.Gridtype === 9) {
        this.comData.forEach(item => {
          if (item.panelId === data.panelId) {
            item.isBtnGroupOpen = !item.isBtnGroupOpen;
            this.$forceUpdate();
          }
        });
        return false;
      }
      this.comData.forEach(item => {
        item.id = item.deviceId || item.paramId || item.groupId || item.templateId || item.deviceTypeId;
      });
      this.comData.forEach(item => {
        if (this.Gridtype === 4) {
          if (item.templateId === data.templateId) {
            item.isBtnGroupOpen = !item.isBtnGroupOpen;
            this.$forceUpdate();
          }
        } else if (item.id === data.id) {
          item.isBtnGroupOpen = !item.isBtnGroupOpen;
          this.$forceUpdate();
        } else {
          item.isBtnGroupOpen = false;
        }
      });
      this.$forceUpdate();
    },
    init() {
      switch (this.Gridtype) {
        case 1:
          this.AddName = '添加参数';
          this.disposeData('nestNumber');
          break;
        case 2:
          this.AddName = '添加基础组';
          this.disposeData('nestNumber');
          break;
        case 3:
          this.AddName = '添加嵌套组';
          this.disposeData('nestNumber');
          break;
        case 4:
          this.AddName = '添加模板';
          this.disposeData('deviceNumber');
          break;
        case 5:
          this.AddName = '添加类型';
          this.disposeData('templateNumber');
          break;
        case 6:
          this.AddName = '注册设备';
          break;
      }
    },
    disposeData(info) {
      if (this.Gridtype !== 6) {
        this.comData.forEach(item => {
          item.amount = item[info];
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.grid_box {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 350px;
  height: 110px;
  border: 2px solid rgba(237, 237, 237, 1);
  border-radius: 6px;
  margin: 6px 20px 6px 0;
  box-shadow: 3px 5px 5px #f5f5f5;
  cursor: pointer;
  transition: 500ms;
  &:hover {
    box-shadow: 4px 6px 6px #e9e9e9;
    transform: scale(0.95);
  }
  .grid_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95px;
    .icon_img {
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
  }
  .grid_detail {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0px;
    .detail_name {
      width: 200px;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #121212;
    }
    .detail_code {
      width: 200px;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: #909399;
    }
    .detail_amount {
      height: 20px;
      font-size: 14px;
      transform: translateX(-2px);
      color: #bdbdbd;
    }
    .Status,
    .bind {
      margin-left: 10px;
    }
    .icon-dcicon-yibangding {
      &::before {
        margin-right: 8px;
      }
    }
  }
  .grid_btn {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 10px;
    right: 0px;
    height: 38px;
    .grid_more {
      text-align: center;
      color: #909399;
      width: 20px;
      height: 16px;
      margin-top: 12px;
      line-height: 10px;
      letter-spacing: 2px;
      transform: rotateZ(90deg);
      span {
        flex: 1;
        height: 5px;
      }
    }
    .grid_more:hover {
      color: #f39800;
    }
    .grid_group {
      display: flex;
      .btn_icon {
        position: relative;
        width: 46px;
        height: 38px;
        border: 1px solid #ededed;
        i {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .btn_icon:hover {
        background-color: #ebebeb;
        i {
          color: #f39800;
        }
      }
      .btn_Del:hover {
        background-color: #ebebeb;
        i {
          color: #ea3837;
        }
      }
    }
  }
}

.grid_view {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  // height: 100%;
}
.ONLINES,
.OFFLINES,
.INACTIVES {
  position: relative;
  // width: 60px;
  height: 25px;
}
.ONLINES::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  width: 5px;
  height: 5px;
  background-color: #19a572;
  border-radius: 50%;
}
.INACTIVES::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  width: 5px;
  height: 5px;
  background-color: #909399;
  border-radius: 50%;
}
.OFFLINES::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  width: 5px;
  height: 5px;
  background-color: #909399;
  border-radius: 50%;
}
</style>
